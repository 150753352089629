<template>
  <div class="page post" :loaded="Boolean(page)">
    <section class="article" v-if="page">
      <div class="wrapper">
        <div class="article-slug">
          <router-link to="/">WestBIC</router-link> /
          <span v-if="page.category">
            <router-link :to="`/${page.category}`">
              {{categories[page.category]}}
            </router-link> /
          </span>
          <router-link :to="`/${page.seo.slug}`">{{page.title}}</router-link>
        </div>
        <h3>{{page.title}}</h3>
      </div>
      <img v-if="page.image" :src="page.image" :alt="page.title" class="article-cover">
      <div class="wrapper small">
        <div class="article-content" v-html="page.content"></div>
      </div>
    </section>
    <section class="return">
      <a class="btn ghost" @click="$router.back()">
        <Icon icon="arrow-left" type="fas" />
        Return to previous page
      </a>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateFormat from "./DateFormat.vue";
import Icon from "./Icon.vue";

export default {
  name: "TemplatePage",
  components: {
    DateFormat,
    Icon,
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    pageId() {
      return this.$route.params.slug;
    },
    slug() {
      const params = this.$route.path.split("/");
      return params[1];
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  data() {
    return {
      page: null,
      categories: {
        'what-we-do': 'What We Do',
        'case-studies': 'Our Clients',
        'news': 'News & Events',
        'initiatives': 'Collaborative Projects',
      },
    };
  },
  watch: {
    pageId(id) {
      this.getPage(id);
    },
    testMode() {
      this.getPage(this.pageId);
    },
  },
  methods: {
    getPage(id) {
      if (this.pageRef) this.pageRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "" : ""}custom_pages`)
        .where("seo.slug", "==", id)
        .onSnapshot((events) => {
          if (events.size) {
            const event = events.docs[0];
            this.page = event.data();
            document.title = this.page.title;
          } else this.page = null;
        });
    },
  },
  mounted: function () {
    this.getPage(this.pageId);
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
  },
};
</script>

<style scoped>
.wrapper {
  width: 1264px;
}

.wrapper.small {
  width: 1008px;
}

.article .article-cover {
  display: block;
  width: 100%;
  height: 44vw;
  object-fit: cover;
  border-radius: 16px;
  margin: 24px 0 42px;
}

.article .article-slug {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
}

.article .article-slug a {
  color: #1DACD6;
  margin: 0 8px;
}

.article .article-slug:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.article .article-content {
  font-size: 18px;
}

.article .article-content >>> a {
  color: #1DACD6 !important;
  text-decoration: underline;
}

.article .article-content >>> img {
  display: block;
  width: 1340px;
  border-radius: 16px;
  margin: 42px 0 24px -190px;
}

.article .article-content >>> h1 {
  font-size: 36px;
  margin-top: 96px;
  margin-bottom: 16px;
}

.article .article-content >>> h1:first-child {
  margin-top: 0;
}

.article .article-content >>> h2 {
  font-size: 22px;
  color: #656662;
  margin: 16px 0 32px;
}

.article .article-content >>> ul {
  list-style: initial;
  margin: initial;
  padding-left: 40px;
}

.article .article-content >>> p {
  margin-top: 0;
}

.article .article-content >>> blockquote {
  position: relative;
  padding: 44px 24px;
  margin: 32px 0;
  border-left: 6px solid rgba(29, 172, 214, 0.49);
  color: #656662;
  font-style: italic;
  font-size: 22px;
}

.article .article-content >>> blockquote:before,
.article .article-content >>> blockquote:after {
  content: '';
  position: absolute;
  width: 24px;
  height: 16px;
  background: url(../assets/images/wb_ic_quotes.svg) no-repeat center/contain;
  opacity: 0.49;
}

.article .article-content >>> blockquote:before {
  top: 16px;
  left: 24px;
}

.article .article-content >>> blockquote:after {
  bottom: 16px;
  right: 24px;
  transform: rotateZ(180deg);
}

.return {
  display: flex;
  justify-content: center;
  margin: 64px 0;
}

@media screen and (max-width: 1340px) {
  .article .article-content >>> img {
    display: block;
    width: 100%;
    margin: 42px 0 24px;
  }
}

@media screen and (max-width: 720px) {
  .article .article-content >>> blockquote {
    font-size: 18px;
  }
}
</style>
